 //
 // [SelectText component]
 // (최종 편집 일자 <2024-08-17>)
 // (편집자 이름 <by. yunseul>)
 // 여러 option 중 사용자가 하나를 선택하는 컴포넌트
 // Col 로 정렬되는 컴포넌트 반환
 // Parameter
 // label 선택지 제목, options 선택 옵션들의 나열, selectedOption, setOption
 //
import React from "react";
import {Label, SelectableText} from "./Styled";
import COLOR from "../config/Color";
import {Col} from "react-bootstrap";

function SelectText({ label, options, selectedOption, setOption }) {
    return (
        <Col>
            <Label style={{marginRight: '8px'}}>
                {label}
            </Label>
            {options.map((option, index) => (
                <React.Fragment key={index}>
                    <SelectableText
                        selected={selectedOption === option}
                        onClick={() => setOption(option)}
                    >
                        {option}
                    </SelectableText>
                    {index < options.length - 1 && (
                        <a style={{ color: COLOR.back_indigo}}> / </a>
                    )}
                </React.Fragment>
            ))}
        </Col>
    );
}

export default SelectText;
