import './App.css';
import Introducing from "./pages/Introducing"
import AboutMe from "./pages/AboutMe";
function App() {
    return (
        <div className="App">
            <img src="/bg.png"
                 alt="background"
                 className="responsive-img"
                 style={{ marginTop: "30px"}} />
            <Introducing/>
            <AboutMe/>
        </div>
    );
}

export default App;
