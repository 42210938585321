//
// [Dropdown Component]
// (최종 편집 일자 <2024-08-19>)
// (편집자 이름 <by. yunseul>)
// 소등 컴포넌트 따로 빼서 정리
// 00시 이후 클릭했을 때 textInput 뜨는 컴포넌트
// Parameter
// label 선택지 제목, options 선택 옵션들의 나열, selectedOption 업데이트 할 선택지 변수명, setOption 업데이트 저장
//
import React, { useState } from "react";
import { Label, SelectableText, Input } from "./Styled";
import { Col } from "react-bootstrap";
import COLOR from "../config/Color";

function SelectText({ label, options, selectedOption, setOption }) {
    const [select, setSelect] = useState(selectedOption === '00시 이후');
    const [customTime, setCustomTime] = useState(selectedOption === '00시 이후' ? '' : selectedOption);

    const UpdateClick = (option) => {
        if (option === '00시 이후') {
            setSelect(true);
            setOption('00시 이후');
        } else {
            setSelect(false);
            setOption(option);
        }
    };
    const UpdateChangeTime = (e) => {
        const newTime = e.target.value;
        setCustomTime(newTime);
        setOption(newTime);
    };
    return (
        <Col>
            <Label style={{ marginRight: '8px' }}>
                {label}
            </Label>
            {options.map((selectTime, index) => (
                <React.Fragment key={index}>
                    {select && selectTime === '00시 이후' ? (
                        <Input
                            type="text"
                            placeholder="00시 이후"
                            value={customTime}
                            onChange={UpdateChangeTime}
                            onBlur={() => {
                                if (!customTime) {
                                    setSelect(false);
                                    setOption('00시 이후');
                                }
                            }}
                            autoFocus
                            style={{ color: COLOR.indigo }}
                        />
                    ) : (
                        <SelectableText
                            selected={selectedOption === selectTime || (selectTime === '00시 이후' && select)}
                            onClick={() => UpdateClick(selectTime)}
                        >
                            {selectTime}
                        </SelectableText>
                    )}
                    {index < options.length - 1 && (
                        <a style={{ color: COLOR.back_indigo }}> / </a>
                    )}
                </React.Fragment>
            ))}
        </Col>
    );
}

export default SelectText;
