//
// [Dropdown Component]
// (최종 편집 일자 <2024-08-18>)
// (편집자 이름 <by. yunseul>)
// 드롭다운 option 중 사용자가 하나를 선택하는 컴포넌트
// Col 로 정렬되는 컴포넌트 반환
// Parameter
// label 선택지 제목, options 선택 옵션들의 나열, selectedOption 업데이트 할 선택지 변수명, setOption 업데이트 저장
//
import React, { useState } from 'react';
import { Label, Select } from "./Styled";
import { Col } from "react-bootstrap";

const Dropdown = ({ label, options, selectedOption, setOption }) => {
    let [isSelected, setIsSelected] = useState(false);

    const UpdateChange = (e) => {
        setOption(e.target.value);
        setIsSelected(true);
    };

    return (
        <Col>
            <Label>{label}:</Label>
            <Select
                value={selectedOption}
                onChange={UpdateChange}
                selected={isSelected}
            >
                {options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </Select>
        </Col>
    );
};

export default Dropdown;
