import { Row, Col, Container } from "react-bootstrap";
import { useState } from "react";
import '../components/Styled';
import SelectText from "../components/SelectText";
import TextInput from "../components/TextInput";
import Dropdown from "../components/Dropdown";

function Introducing() {
    const [state, setState] = useState({
        gender: "",
        number: "",
        age: "",
        period: "",
        grade: "",
        college: "",
    });

    const UpdateChange = (key, value) => {
        setState(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };


    return (
        <Row
            className="jejudoldam"
            style={{
                position: 'absolute',
                top:'14%',
                width: '1198px',
                height: '157px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Container>
                <Row>
                    <SelectText
                        label={'성별:'}
                        options={['남자', '여자']}
                        selectedOption={state.gender}
                        setOption={(value) => UpdateChange('gender', value)}
                    />
                    <SelectText
                        label={'생활관:'}
                        options={['2', '3']}
                        selectedOption={state.number}
                        setOption={(value) => UpdateChange('number', value)}
                    />
                    <TextInput
                        label="나이"
                        placeholder="00살"
                        selectedOption={state.age}
                        setOption={(value) => UpdateChange('age', value)}
                    />
                </Row>
                <Row>
                    <SelectText
                        label={'거주 기간:'}
                        options={['학기', '반기']}
                        selectedOption={state.period}
                        setOption={(value) => UpdateChange('period', value)}
                    />
                    <TextInput
                        label="학번(학년)"
                        placeholder="00 (1)"
                        selectedOption={state.grade}
                        setOption={(value) => UpdateChange('grade', value)}
                    />
                    <Dropdown
                        label={'단과대'}
                        options={['경영대', '사과대','법대','공대','바나대','반도체','예체대','아융대','의대','약대','간호대','한의대']}
                        selectedOption={state.college}
                        setOption={(value) => UpdateChange('college', value)}
                    />
                </Row>
            </Container>
        </Row>
    );
}

export default Introducing;
