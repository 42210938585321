import React from 'react';
import { Input, Label } from "./Styled";
import { Col } from "react-bootstrap";

const TextInput = ({ label, placeholder, selectedOption, setOption }) => {
    const UpdateChange = (e) => {
        setOption(e.target.value);
    };

    return (
        <Col>
            <Label>
                {label}:
            </Label>
            <Input
                type="text"
                placeholder={placeholder}
                value={selectedOption}
                onChange={UpdateChange}
                hasValue={selectedOption !== ""}
            />
        </Col>
    );
};

export default TextInput;
