import styled from "styled-components";
import COLOR from "../config/Color";

export const Label = styled.label`
    color: ${COLOR.indigo};
`;

export const SelectableText = styled.span`
    cursor: pointer;
    color: ${(props) => (props.selected ? COLOR.indigo : COLOR.back_indigo)};
`;
export const Select = styled.select`
    padding: 5px;
    background-color: transparent;
    border: none;
    outline: none;
    color: ${(props) => (props.selected ? COLOR.indigo : COLOR.back_indigo)};
`;

export const Input = styled.input`
    width: 80px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: ${(props) => (props.hasValue ? COLOR.indigo : COLOR.back_indigo)};
    &::placeholder {
        color: ${COLOR.back_indigo};
    }
`;
export const Text = styled.span`
    cursor: pointer;
    color: ${(props) => (props.selected ? COLOR.indigo : COLOR.back_indigo)};
`;