import { Row, Col, Container } from "react-bootstrap";
import { useState } from "react";
import TextInput from "../components/TextInput";
import SelectText from "../components/SelectText";
import {Label} from "../components/Styled";
import SelectInput from "../components/SelectInput";

function AboutMe() {
    const [state, setState] = useState({
        // sleepPeriod: '오후',
        // sleepTime:'0',
        sleep: '',
        smoke: '',
        wake: '',
        returnTime: '',
        dryMorning: '',
        dryEvening: '',
        clean: '',
        sleepEar: '', //잠귀 ^_^
        habit: '',
        shower: '',
        eat: '',
        turnOff: '',
        goHome: '',
        earPhones: '',
        keySkin: ''
    });

    const UpdateChange = (key, value) => {
        setState(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    return (
        <Row className="jejudoldam">
            <Container>
                <Row>
                {/*<SelectTime*/}
                {/*    label={'취침 시간'}*/}
                {/*    selectedPeriod={state.sleepPeriod}*/}
                {/*    setPeriod={(value) => UpdateChange('sleepPeriod', value)}*/}
                {/*    timeValue={state.sleepTime}*/}
                {/*    setTime={(value) => UpdateChange('sleepTime', value)}*/}
                {/*/>*/}
                <TextInput
                    label="취침 시간"
                    placeholder="오후 0시"
                    selectedOption={state.sleep}
                    setOption={(value) => UpdateChange('sleep', value)}
                />
                <SelectText
                    label={'흡연 여부:'}
                    options={['O', 'X']}
                    selectedOption={state.smoke}
                    setOption={(value) => UpdateChange('smoke', value)}
                />
                </Row>
                <Row>
                    <TextInput
                        label="기상 시간"
                        placeholder="오전 0시"
                        selectedOption={state.wake}
                        setOption={(value) => UpdateChange('wake', value)}
                    />
                    <Col>
                        <Label>드라이기 사용:</Label>
                    </Col>
                </Row>
                <Row>
                    <TextInput
                        label="귀가 시간"
                        placeholder="오후 0시"
                        selectedOption={state.returnTime}
                        setOption={(value) => UpdateChange('returnTime', value)}
                    />
                    <SelectText
                        label={'아침은'}
                        options={['화장실', '휴게실']}
                        selectedOption={state.dryMorning}
                        setOption={(value) => UpdateChange('dryMorning', value)}
                    />
                </Row>
                <Row>
                    <Col/>
                    <SelectText
                        label={'저녁은'}
                        options={['화장실', '휴게실']}
                        selectedOption={state.dryEvening}
                        setOption={(value) => UpdateChange('dryEvening', value)}
                    />
                </Row>
                <Row>
                    <SelectText
                        label={'청소:'}
                        options={['주기적', '비주기적']}
                        selectedOption={state.clean}
                        setOption={(value) => UpdateChange('clean', value)}
                    />
                </Row>
                <Row>
                    <SelectText
                        label={'잠귀:'}
                        options={['밝음', '어두움']}
                        selectedOption={state.sleepEar}
                        setOption={(value) => UpdateChange('sleepEar', value)}
                    />
                </Row>
                <Row>
                    <SelectText
                        label={'잠 버릇:'}
                        options={['심한 편', '중간','별로 없음']}
                        selectedOption={state.habit}
                        setOption={(value) => UpdateChange('habit', value)}
                    />
                </Row>
                <Row>
                    <SelectText
                        label={'샤워 시간:'}
                        options={['아침', '저녁']}
                        selectedOption={state.shower}
                        setOption={(value) => UpdateChange('shower', value)}
                    />
                </Row>
                <Row>
                    <SelectText
                        label={'방에서 취식:'}
                        options={['가능', '불가능','가능 + 환기 필수']}
                        selectedOption={state.eat}
                        setOption={(value) => UpdateChange('eat', value)}
                    />
                </Row>
                <Row>
                    <SelectInput
                        label={'소등:'}
                        options={['00시 이후', '한명 잘 때 알아서']}
                        selectedOption={state.turnOff}
                        setOption={(value) => UpdateChange('turnOff', value)}
                    />
                </Row>
                <Row>
                    <SelectText
                        label={'본가 가는 주기:'}
                        options={['매주', '2주','한 달 이상','거의 안 감']}
                        selectedOption={state.goHome}
                        setOption={(value) => UpdateChange('goHome', value)}
                    />
                </Row>
                <Row>
                    <SelectText
                        label={'방에서 이어폰 착용:'}
                        options={['항상','유동적']}
                        selectedOption={state.earPhones}
                        setOption={(value) => UpdateChange('earPhones', value)}
                    />
                </Row>
                <Row>
                    <SelectText
                        label={'방에서 키스킨 착용:'}
                        options={['항상','유동적']}
                        selectedOption={state.keySkin}
                        setOption={(value) => UpdateChange('keySkin', value)}
                    />
                </Row>
            </Container>
        </Row>
    );
}

export default AboutMe;
